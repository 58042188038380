body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.ck-header {
  background-color: #101010;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.ck-footer {
  background-color: #101010;
  padding-top: 30px;
  padding-bottom: 30px;
  color: white;
}

.ck-footer .ui.vertical.text.menu .header.item {
  color: white;
}

.ck-footer .ui.vertical.menu.ck-footer-info {
  width: 100%;
  color: #ccc;
}

.ck-footer a {
  color: white;
}

.ck-footer a:hover {
  color: #ccc;
}

.ck-title-section {
  margin-top: 2em;
  margin-bottom: 4em;
}

.ck-title-section h1.ui.header {
  font-size: 3em;
}

.ck-title-section p.lead {
  font-size: 1.5em;
}

.ck-title-section.inverted h1.ui.header {
  color: white;
}

.ck-title-section.inverted p.lead {
  color: #F1F1F1;
}

.ck-title-section.inverted a.backlink {
  color: #ccc;
}

.backlink {
  cursor: pointer;
}

p.lead {
  font-size: 130%;
}

.ck-segment-dark p {
  color: #ccc; 
}

p.lead.emphasis {
  color: #F92572;
}

.ck-hero h1.ui.header {
  font-size: 2em;
  margin-top: 1.5em;
  font-weight: normal;
}

.ck-hero h2.ui.header {
  font-size: 1.5em;
  margin-top: 1em;
  font-weight: normal;
}

.ui.segment.ck-hero {
  padding-bottom: 3em;
}

i.ck-pink.icon {
  color: #F92572;
}

@media only screen and (min-width: 768px) {
  .ck-hero h1.ui.header {
    font-size: 4em;
  }

  .ck-hero h2.ui.header {
    font-size: 1.7em;
    margin-top: 1.5em;
  }

  .ui.segment.ck-hero {
    padding-bottom: 6em;
  }
}

@media only screen and (max-width: 767px) {
  .ck-footer .column {
    text-align: center !important;
  }
}